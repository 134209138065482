import axios from 'axios';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {ApiCheckOtpResponse, ApiResendOtpResponse, ApiSendOtpResponse} from "@tarimli-mono/api/src/shared/api-types.ts";
import {config} from "../config.ts";

const sendOtp = async (phone: string, method: 'sms' | 'whatsapp', ): Promise<ApiSendOtpResponse> => {
  const url = `${config().apiPrefix}/otp/send`;
  try {
    const {data} = await axios.post(url, {phone, method});
    console.log('POST send-otp', data);
    return data satisfies ApiSendOtpResponse;
  } catch (e) {
    console.error(e);
    return {success: false, error: 'server-error'} satisfies ApiSendOtpResponse;
  }
}

export const useSendOtpMutation = () => {
  return useMutation({
    mutationFn: ({phone, method}: { phone: string, method: 'sms' | 'whatsapp'  }) => {
      return sendOtp(phone, method);
    },
  });
}

const checkOtp = async (phone: string, otp: string, otpToken, isPartner: boolean = false): Promise<ApiCheckOtpResponse> => {
  const url = `${config().apiPrefix}/otp/check`;
  try {
    const {data} = await axios.post(url, {phone, otp, isPartner, otpToken});
    console.log('POST verify-otp', data);
    return data satisfies ApiCheckOtpResponse;
  } catch (e) {
    console.error(e);
    return {success: false, error: 'server-error'} satisfies ApiCheckOtpResponse;
  }
}

export const useCheckOtpMutation = (isPartner: boolean = false) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({phone, otp, otpToken}: { phone: string, otp: string, otpToken: string}) => {
      return checkOtp(phone, otp, otpToken, isPartner);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['current-user'], data);
    },
  });
}

const resendOtp = async (otpToken: string, method: 'sms' | 'whatsapp'): Promise<ApiResendOtpResponse> => {
  const url = `${config().apiPrefix}/otp/resend`;
  try {
    const {data} = await axios.post(url, {otpToken, method});
    console.log('POST resend-otp', data);
    return data satisfies ApiResendOtpResponse;
  } catch (e) {
    console.error(e);
    return {success: false, error: 'server-error'} satisfies ApiResendOtpResponse;
  }
}

export const useResendOtpMutation = () => {
  return useMutation({
    mutationFn: ({otpToken, method}: { otpToken: string, method: 'sms' | 'whatsapp' }) => {
      return resendOtp(otpToken, method);
    },
  });
}