import Face6Icon from "@mui/icons-material/Face6";
import Stack from "@mui/material/Stack";
import React from "react";
import Box from "@mui/material/Box";
import {ClientPartner} from "../model/client-partner.ts";
import {PartnerRating} from "./partner-rating.tsx";

export function ProfilePhoto({partner}: { partner: ClientPartner }) {
  if (!partner.publicProfile.profilePhotos?.[0]) {
    return <Face6Icon sx={{fontSize: 40, opacity: 0.3}}/>;
  }
  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        borderRadius: 1,
        overflow: 'hidden',
        backgroundColor: 'white',
        boxShadow: 1,
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}>
      <img src={partner.publicProfile.profilePhotos?.[0]}/>
    </Box>

  );
}

export function PartnerProfile({partner}: { partner: ClientPartner }) {
  return (
    <Stack direction={'row'} gap={2} sx={{
      padding: 2,
    }}>
      <Box sx={{flexBasis: 100}}>
        <ProfilePhoto partner={partner} />
      </Box>
      <Stack gap={2} sx={{flexGrow: 1}}>
        <PartnerRating partner={partner.publicProfile}/>
      </Stack>
    </Stack>
  );
}