import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Face6Icon from '@mui/icons-material/Face6';
import LanguageIcon from '@mui/icons-material/Language';
import numbro from 'numbro';
import facebookLogo from "/facebook-logo.png";
import instagramLogo from "/instagram-logo.svg";
import twitterLogo from "/twitter-logo.png";
import tiktokLogo from "/tiktok-logo.png";
import dcoilLogo from "/dcoil-logo.png";
import prosLogo from "/pros-logo.png";
import easyLogo from "/easy-logo.png";
import midragLogo from "/midrag-logo.png";
import Grid from '@mui/material/Grid2';
import Box from "@mui/material/Box";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import {AggregatedRating} from "@tarimli-mono/api/src/shared/rating.ts";
import {ClientPartnerPublicProfile} from "../model/client-partner.ts";

const labels = {
  ratingCount: (count: number) => count === 1 ? 'ביקורת אחת:' : `${count} ביקורות:`,
  reviews: 'חוות דעת',
  quality: 'איכות ההובלה',
  timing: 'עמידה בזמנים',
  service: 'שירות המובילים',
  experience: 'חווית השימוש בתריםלי',
}

const NoRating = () => {
  return null;
  // return <Typography sx={{fontSize: 12, opacity: 0.5}}>לא דורג</Typography>
}

export const RatingBox = ({rating}: { rating: AggregatedRating }) => {
  return (
    <Stack gap={2}>
      <PartnerBasicRating rating={rating}/>
      {rating.ratings.length > 0 && <Stack gap={2}>
        <Typography sx={{fontSize: 14, opacity: 0.5}}>{labels.ratingCount(rating?.count)}</Typography>
      </Stack>
      }
      {
        rating.ratings.map((review, i) => (
          <Stack gap={2}>
            <Stack direction={'row'} key={review.createdAt.toISOString()}>
              <Stack flex={1.5}>
                {/*<Rating sx={{color: 'grey', opacity: 0.5}} value={Number(numbro(review.avgRating).format({*/}
                {/*  trimMantissa: true,*/}
                {/*  mantissa: 1*/}
                {/*}))} precision={0.5} size={'small'} readOnly/>*/}
                <Stack gap={0} alignItems={'flex-start'}>
                  <Stack direction={'row'} gap={1} alignItems={'center'}>
                    {review.review.name &&
                      <Stack direction={'row'} gap={0.5} alignItems={'center'} sx={{opacity: 0.5}}><Face6Icon
                        sx={{fontSize: 12}}/><Typography sx={{fontSize: 12}}>{review.review.name}</Typography></Stack>}
                    {review.review.createdAt &&
                      <Stack direction={'row'} gap={0.5} alignItems={'center'} sx={{opacity: 0.5}}><CalendarMonthIcon
                        sx={{fontSize: 12}}/><Typography
                        sx={{fontSize: 12}}>{review.getCreatedAtFormatted || null}</Typography></Stack>}
                  </Stack>
                  <Typography sx={{fontSize: 16, opacity: 0.5}}>{review.review.comments}</Typography>
                </Stack>
              </Stack>
              <Stack flex={1}>
                <Stack gap={1}>
                  <Stack gap={0}>
                    <Typography sx={{fontSize: 12, opacity: 0.5}}>{labels.quality}</Typography>
                    <Rating sx={{color: 'grey', opacity: 0.5}} value={review.quality} precision={0.5} size={'small'}
                            readOnly/>
                  </Stack>
                  <Stack gap={0}>
                    <Typography sx={{fontSize: 12, opacity: 0.5}}>{labels.timing}</Typography>
                    <Rating sx={{color: 'grey', opacity: 0.5}} value={review.timing} precision={0.5} size={'small'}
                            readOnly/>
                  </Stack>
                  <Stack gap={0}>
                    <Typography sx={{fontSize: 12, opacity: 0.5}}>{labels.service}</Typography>
                    <Rating sx={{color: 'grey', opacity: 0.5}} value={review.service} precision={0.5} size={'small'}
                            readOnly/>
                  </Stack>

                </Stack>
              </Stack>
            </Stack>
            <Divider/>
          </Stack>
        ))
      }
    </Stack>
  );
}

export const PartnerSocials = ({partner}: { partner: ClientPartnerPublicProfile }) => {
  return <Stack direction={'row'} gap={2} alignItems={'center'}>
    {partner.socials?.website?.url && <Box>
      <a href={partner.socials?.website.url} target={'_blank'} rel={'noreferrer'}>
        <LanguageIcon sx={{fontSize: 35}}/>
      </a>
    </Box>}
    {partner.socials?.facebook?.url && <Box>
      <a href={partner.socials?.facebook?.url} target={'_blank'} rel={'noreferrer'}>
        <img src={facebookLogo} style={{width: 30, height: 30}}/>
      </a>
    </Box>
    }
    {partner.socials?.instagram?.url && <Box>
      <a href={partner.socials?.instagram?.url} target={'_blank'} rel={'noreferrer'}>
        <img src={instagramLogo} style={{width: 30, height: 30}}/>
      </a>
    </Box>
    }
    {partner.socials?.twitter?.url && <Box>
      <a href={partner.socials?.twitter?.url} target={'_blank'} rel={'noreferrer'}>
        <img src={twitterLogo} style={{width: 30, height: 30}}/>
      </a>
    </Box>
    }
    {
      partner.socials?.tiktok?.url && <Box>
        <a href={partner.socials?.tiktok?.url} target={'_blank'} rel={'noreferrer'}>
          <img src={tiktokLogo} style={{width: 30, height: 30}}/>
        </a>
      </Box>
    }
  </Stack>
}

export const PartnerBasicRating = ({rating}: { rating: AggregatedRating }) => {
  if (rating?.hasRatings) {
    return <Stack direction={'row'} spacing={1} alignItems={'flex-start'} sx={{
      p: 1,
      borderRadius: 1,
    }}>
      <Stack>
        {/*<Stack direction={'row'} spacing={1} alignItems={'center'}>*/}
        {/*  <Typography>{rating?.avgRating}</Typography>*/}
        {/*  <Rating value={rating?.avgRating} precision={0.5} readOnly/>*/}
        {/*</Stack>*/}
        <Stack gap={1}>
          <Stack gap={0}>
            <Typography sx={{fontSize: 14, opacity: 0.5}}>{labels.quality}</Typography>
            <Rating sx={{}} value={rating.avgQuality} precision={0.5} size={'large'}
                    readOnly/>
          </Stack>
          <Stack gap={0}>
            <Typography sx={{fontSize: 14, opacity: 0.5}}>{labels.timing}</Typography>
            <Rating sx={{}} value={rating.avgTiming} precision={0.5} size={'large'}
                    readOnly/>
          </Stack>
          <Stack gap={0}>
            <Typography sx={{fontSize: 14, opacity: 0.5}}>{labels.service}</Typography>
            <Rating sx={{}} value={rating.avgService} precision={0.5} size={'large'}
                    readOnly/>
          </Stack>

        </Stack>
      </Stack>
    </Stack>;
  } else {
    return null;
  }
}

export const PartnerRating = ({partner}: { partner: ClientPartnerPublicProfile }) => {
  return (
    <Stack gap={2}>
      <PartnerSocials partner={partner}/>
      {partner?.rating?.hasRatings ? <RatingBox rating={partner.rating}/> : null}
      <Stack gap={0}>
        {
          partner.socials?.midrag?.rating ? <Stack direction={'row'} gap={1} alignItems={'center'}>
            <Box sx={{width: 50, height: 50, outline: 'none', position: 'relative'}}>
              <img src={midragLogo} style={{
                borderRadius: '5px',
                width: 30,
                height: 30,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}/>
            </Box>

            <Rating value={partner.socials?.midrag?.rating} precision={0.5} readOnly size={'small'}
                    sx={{color: 'grey', opacity: 0.5}}/>
            <Typography sx={{fontSize: 16, opacity: 0.5}}>{Number(numbro(partner.socials?.midrag?.rating).format({
              trimMantissa: true,
              mantissa: 1
            }))}</Typography>
          </Stack> : null
        }
        {
          partner.socials?.pros?.rating ? <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{width: 50, height: 50, outline: 'none', position: 'relative'}}>
              <img src={prosLogo} style={{
                borderRadius: '5px',
                width: 50,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}/>
            </Box>
            <Rating value={partner.socials?.pros.rating} precision={0.5} readOnly size={'small'}
                    sx={{color: 'grey', opacity: 0.5}}/>
            <Typography sx={{fontSize: 16, opacity: 0.5}}>{Number(numbro(partner.socials?.pros?.rating).format({
              trimMantissa: true,
              mantissa: 1
            }))}</Typography>
          </Stack> : null
        }
        {
          partner.socials?.dcoil?.rating ? <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{width: 50, height: 50, outline: 'none', position: 'relative'}}>
              <img src={dcoilLogo} style={{
                borderRadius: '5px',
                width: 30,
                height: 30,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}/>
            </Box>
            <Rating value={partner.socials?.dcoil?.rating} precision={0.5} readOnly size={'small'}
                    sx={{color: 'grey', opacity: 0.5}}/>
            <Typography sx={{fontSize: 16, opacity: 0.5}}>{Number(numbro(partner.socials?.dcoil?.rating).format({
              trimMantissa: true,
              mantissa: 1
            }))}</Typography>
          </Stack> : null
        }
        {
          partner.socials?.easy?.rating ? <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{width: 50, height: 50, outline: 'none', position: 'relative'}}>
              <img src={easyLogo} style={{
                borderRadius: '5px',
                width: 30,
                height: 30,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}/>
            </Box>
            <Rating value={partner.socials?.easy?.rating} precision={0.5} readOnly size={'small'}
                    sx={{color: 'grey', opacity: 0.5}}/>
            <Typography sx={{fontSize: 16, opacity: 0.5}}>{Number(numbro(partner.socials?.midrag?.rating).format({
              trimMantissa: true,
              mantissa: 1
            }))}</Typography>
          </Stack> : null
        }
      </Stack>
    </Stack>
  );
}