import { useSuspenseQuery } from "@tanstack/react-query";
import {ApiCurrentUserResponse} from "@tarimli-mono/api/src/shared/api-types.ts";
import {useAppState} from "./state.ts";
import {authGet} from "./api-call.ts";

export const useQuerySuspenseCurrentUser = (onUnauthorized?: () => void) => {
  const appState = useAppState();
  const {isFetching, isError, error, data} = useSuspenseQuery({
    queryKey: ['current-user'],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      if (!appState.userToken) {
        return null;
      }
      const data = await authGet<ApiCurrentUserResponse>('/current-user', onUnauthorized);
      if (!data || !data.clientCustomer && !data.clientPartner) {
        appState.setUserToken(null);
        return null;
      }
      // await new Promise(resolve => setTimeout(resolve, 3000));
      return data;
    },
  });
  return {
    isFetching,
    isError,
    error,
    data,
  };
}

