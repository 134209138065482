import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import {CircularProgressWithLabel} from "./progress.tsx";
import Box from "@mui/material/Box";

export function PhotoGallery({
                               innerElement,
                               images,
                               size,
  progress,
  progressIndex,
                             }: {
  innerElement?: (idx: number) => React.ReactNode,
  size?: string,
  progress?: number | null,
  progressIndex?: number | null,
  images: {
    src: string,
    width: number,
    height: number,
    title: string,
    description?: string,
  }[]
}) {
  return <LightGallery plugins={[lgZoom]}>
    {
      images.map(({src, width, height, title, description}, index) => (
        <a
          key={index}
          data-src={src}
          data-sub-html={`<h2>${title}</h2>${description ? `<p>${description}</p><br />` : ''}`}
        >
          <div style={{position: 'relative', float: 'right', width: size ?? '3em', height: size ?? '3em', marginLeft: '10px', borderRadius: '5px', backgroundColor: '#f4f4f4'}}>
            {innerElement ? innerElement(index) : null}
            <img
              style={{width: size ?? '3em', height: size ?? '3em', borderRadius: '5px'}}
              className="img-responsive"
              src={src}
            />
            {typeof progress === 'number' && progressIndex === index && <Box sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}>
              <CircularProgressWithLabel hideLabel color="primary" thickness={5} size={25} value={0}/>
            </Box>}
          </div>
        </a>
      ))
    }
  </LightGallery>

}

// import React, { useEffect } from 'react';
// import PhotoSwipeLightbox from 'photoswipe/lightbox';
// import PhotoSwipe from 'photoswipe';
// import 'photoswipe/style.css';
//
// export default function PhotoGallery(props) {
//   useEffect(() => {
//     let lightbox = new PhotoSwipeLightbox({
//       gallery: '#' + props.galleryID,
//       children: 'img',
//       pswpModule: PhotoSwipe,
//     });
//     lightbox.init();
//
//     return () => {
//       lightbox.destroy();
//       lightbox = null;
//     };
//   }, []);
//
//   return (
//     <div className="pswp-gallery" id={props.galleryID}>
//       {props.images.map((image, index) => (
//         <img key={props.galleryID + '-' + index} src={image.src} alt=""/>
//       ))}
//     </div>
//   );
// }
