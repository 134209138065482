import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

export function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number, hideLabel?: boolean },
) {
  return (
    <Stack direction={'row'} gap={1}>
      <CircularProgress variant={ props.value > 1 && props.value < 100 ? 'determinate' : 'indeterminate' } {...props} />
      {props.value > 1 && props.value < 100 ? <Box
        sx={{
          // top: 0,
          // left: 0,
          // bottom: 0,
          // right: 0,
          // position: 'absolute',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        {!props.hideLabel && <Typography
          variant="caption"
          component="div"
          sx={{color: 'text.secondary'}}
        >{`${Math.round(props.value)}%`}</Typography>}
      </Box> : null }
    </Stack>
  );
}
