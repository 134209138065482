import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from '@emotion/cache';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    greenAction: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    formError: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    formError?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formError: true;
  }
}

const theme = createTheme({
  direction: 'rtl',
  typography: {

    fontFamily: "Noto Sans Hebrew, sans-serif",
    formError: {
      color: 'red',
    }
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: "#AD7AFF"
    },
    secondary: {
      main: "#C9C0FF",
    },
    error: {
      main: red.A400
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          formError: 'span',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          shadow: 'none',
          // backgroundColor: "#AD7AFF",
          // color: 'white',
        },
        outlined: {
          // borderWidth: 1.5,
          fontWeight: 500,
          // backgroundColor: "#f3e6ff",
          // color: 'white',
        }
      },
      variants: [
        {
          props: { variant: 'greenAction' },
          style: {
            backgroundColor: "#25D366",
            color: 'white',
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: { variant: 'outlined' },
          
          style: {
            // backgroundColor: '#f3e6ff',
          },
        },
      ],
    },
  },

});

export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

export default theme;
