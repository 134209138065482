import dayjs from 'dayjs';
import {
  COrderStatus,
  ICCustomerOwnOrder,
  ICOrderForPartner
} from "@tarimli-mono/api/src/shared/client-types.ts";
import {OrderContent} from "@tarimli-mono/api/src/shared/order-content.ts";
import {OrderRating} from "@tarimli-mono/api/src/shared/rating.ts";
import {ClientOfferForCustomer, ClientPartnerOwnOffer} from "./client-offer.ts";
import {config} from "../config.ts";
import {ClientCustomer} from "./client-customer.ts";

export class ClientCustomerOwnOrder {
  id: ICCustomerOwnOrder['id'];
  customer: ClientCustomer;
  childrenIds?: ICCustomerOwnOrder['childrenIds'];
  parentId?: ICCustomerOwnOrder['parentId'];
  customerId: ICCustomerOwnOrder['customerId'];
  status: ICCustomerOwnOrder['status'];
  content: OrderContent;
  submittedOffers: ClientOfferForCustomer[];
  acceptedOffer: ClientOfferForCustomer | null;
  review: OrderRating | null;
  paymentData?: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    paymentsNum: number;
  };
  finalAmount?: number;
  readonly createdAt: dayjs.Dayjs;
  readonly updatedAt: dayjs.Dayjs;

  constructor(order: ICCustomerOwnOrder, customer: ClientCustomer) {
    this.id = order.id;
    this.customer = customer;
    this.childrenIds = order.childrenIds;
    this.parentId = order.parentId;
    this.customerId = order.customerId;
    this.status = order.status;
    this.content = new OrderContent(order.id, order.content, order.items);
    this.submittedOffers = order.submittedOffers.map(offer => new ClientOfferForCustomer(offer));
    this.acceptedOffer = order.acceptedOffer ? new ClientOfferForCustomer(order.acceptedOffer) : null;
    this.review = order.review && new OrderRating(order.review) || null;
    this.paymentData = order.paymentData;
    this.finalAmount = order.finalAmount;
    this.createdAt = dayjs(order.createdAt);
    this.updatedAt = dayjs(order.updatedAt);
  }

  get isEdit() {
    return this.status === 'draft' && ['open', 'closed'].includes(this.parent?.status);
  }
  
  get hasChildren(): boolean {
    return this.childrenIds && this.childrenIds.length > 0;
  }
  
  get hasSiblings(): boolean {
    return this.siblings.length > 0;
  }
  
  get parent(): ClientCustomerOwnOrder | null {
    if (!this.parentId) {
      return null;
    }
    return this.customer.orders.find(order => order.id === this.parentId) || null;
  }
  
  get siblings(): ClientCustomerOwnOrder[] {
    if (!this.parentId) {
      return [];
    }
    return this.customer.orders.filter(order => order.parentId === this.parentId && order.id !== this.id);
  }
  
  get children(): ClientCustomerOwnOrder[] {
    return this.customer.orders.filter(order => this.childrenIds?.includes(order.id));
  }
  
  get isChild(): boolean {
    return !!this.parent;
  }
  
  get hasOffers(): boolean {
    return this.submittedOffers.length > 0;
  }

  get hasEnoughOffersToDisplay(): boolean {
    return this.submittedOffers.length > 0;
  }
}

export class ClientOrderForPartner {
  id: DbId;
  status: COrderStatus;
  content: OrderContent;
  offer: ClientPartnerOwnOffer | null;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(order: ICOrderForPartner) {
    this.id = order.id;
    this.status = order.status;
    this.content = new OrderContent(order.id, order.content, order.items);
    this.offer = order.offer && new ClientPartnerOwnOffer(order.offer) || null;
    this.createdAt = dayjs(order.createdAt);
    this.updatedAt = dayjs(order.updatedAt);
  }
  
  get isExpired(): boolean {
    return this.status === 'closed' || this.status === 'completed';
  }
  
}
