import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const ScreenLoader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0)',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
