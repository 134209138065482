import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      // Sentry.captureConsoleIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.sessionTimingIntegration(),
    ],
    // debug: true,
    autoSessionTracking: true,
    enableTracing: true,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^http:\/\/localhost:5500/, /^https:\/\/api\.tarimli\.com/],
    // Session Replay
    replaysSessionSampleRate: 0., // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
