import { createFileRoute, Navigate } from '@tanstack/react-router'
import { useAuth } from '../services/auth.tsx'

export const Route = createFileRoute('/partner-login')({
  component: Index,
})

function Index() {
  return <Navigate to="/" />;
}
