import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/link')({
  beforeLoad: ({context}) => {
    const searchParams = new URLSearchParams(location.search);
    const urlToken = searchParams.get('token');
    const linkType = searchParams.get('type');
    if (urlToken && linkType) {
      // setStoredToken(urlToken);
      switch(linkType) {
        case 'wa_order_submitted':
          /* @ts-expect-error */
          throw redirect({
            to: '/orders',
          });
        case 'wa_new_order_for_partner':
          /* @ts-expect-error */
          throw redirect({
            to: '/partner/orders',
          });
      }
    }
    /* @ts-expect-error */
    throw redirect({
      to: '/',
    });
  },
  component: () => null,
})
