import { Route, useMatch, useMatchRoute } from "@tanstack/react-router";
import {useCustomer, usePartner} from "./auth.tsx";

export const useCustomerRouteOrder = (routePath: string) => {
  const matchRoute = useMatchRoute()
  const routeParams = matchRoute({ to: routePath }) as { orderId: string };
  const customer = useCustomer();
  const order = customer.findOrder(Number(routeParams.orderId));
  return order || null;
}

export const usePartnerRouteOrder = (routePath: string) => {
  const matchRoute = useMatchRoute()
  const routeParams = matchRoute({ to: routePath }) as { orderId: string };
  const partner = usePartner();
  const order = partner.findOrder(Number(routeParams.orderId));
  return order || null;
}