import Stack from '@mui/material/Stack'
import {createFileRoute, useRouter} from '@tanstack/react-router'
import Typography from "@mui/material/Typography";
import React from "react";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {PartnerProfile} from "../../../components/partner-profile.tsx";
import {usePartner} from "../../../services/auth.tsx";

export const Route = createFileRoute('/_auth/partner/profile')({
  component: PartnerProfileScreen,
});


export function PartnerProfileScreen() {
  const partner = usePartner();
  const router = useRouter();
  const onClickBack = async () => {
    // @ts-expect-error
    await router.navigate({
      to: `/partner/orders`,
      replace: true,
    });
  }
  return <Stack sx={{
    margin: 2,
    backgroundColor: 'white',
    borderRadius: 1,
    clipPath: 'padding-box',
  }}>
    <Box sx={{
      padding: 2,
      backgroundColor: '#F3EBFF',
    }}>
      <Stack direction={"row"} gap={2} alignItems={"center"}>
        <IconButton onClick={onClickBack} sx={{
          backgroundColor: "#EADCFF",
        }}>
          <ChevronRightIcon/>
        </IconButton>
        <Typography variant={'h5'}>{partner.businessName}</Typography>
      </Stack>
    </Box>
    <PartnerProfile partner={partner}/>
  </Stack>
}
