import {convertToOneLine} from "./util";

const ITEM_COMMENT_MAX_LENGTH = 100;

export type OrderItemPropsCheckboxConfig = {
  label: string;
  description?: string;
  defaultValue?: boolean;
}
export type OrderItemPropsRadioConfig = {
  label: string;
  description?: string;
  options: string[];
  defaultValue?: string;
}
export type OrderItemPropsSelectConfig = {
  label: string;
  description?: string;
  options: string[];
  defaultValue?: string;
}
export type OrderItemPropsCounterConfig = {
  label: string;
  description?: string;
  minValue?: number;
  maxValue?: number | null;
  defaultValue?: number;
}
export type OrderItemPropsTextConfig = {
  label: string;
  description?: string;
  maxLength?: number;
  defaultValue?: string;
}
export type OrderItemPropsMediaConfig = {
  label: string;
  description?: string;
  maxCount?: number;
  mediaIds?: string[];
  mediaTypes?: ('image' | 'video')[];
  defaultValue?: [];
}
export type OrderItemPropsType = 'checkbox' | 'radio' | 'select' | 'counter' | 'text' | 'media';
export type OrderItemPropsValue = boolean | string | number;
export type OrderItemProps = {
  type: OrderItemPropsType;
  value: OrderItemPropsValue;
  config: OrderItemPropsCheckboxConfig;
} | {
  type: OrderItemPropsType;
  value: OrderItemPropsValue;
  config: OrderItemPropsRadioConfig;
} | {
  type: OrderItemPropsType;
  value: OrderItemPropsValue;
  config: OrderItemPropsSelectConfig;
} | {
  type: OrderItemPropsType;
  value: OrderItemPropsValue;
  config: OrderItemPropsCounterConfig;
} | {
  type: OrderItemPropsType;
  value: OrderItemPropsValue;
  config: OrderItemPropsTextConfig;
} | {
  type: OrderItemPropsType;
  value: OrderItemPropsValue;
  config: OrderItemPropsMediaConfig;
};

export type OrderItemData = {
  id: string;
  title: string;
  quantity: number;
  comments?: string;
  assembly?: boolean;
  disassembly?: boolean;
  throwAway?: boolean;
  itemProps?: OrderItemProps[];
  media?: [mediaId: string, mediaUrl: string][];
}

export class OrderItem {
  data: OrderItemData;
  id: string;
  title: string;
  quantity: number;
  comments?: string;
  assembly?: boolean;
  disassembly?: boolean;
  throwAway?: boolean;
  itemProps?: OrderItemProps[];
  media?: [mediaId: string, mediaUrl: string][];

  constructor(data: OrderItemData) {
    const sanitizedData = OrderItem.sanitize(data);
    this.data = data;
    this.id = sanitizedData.id;
    this.title = sanitizedData.title;
    this.quantity = sanitizedData.quantity;
    this.comments = sanitizedData.comments;
    this.assembly = sanitizedData.assembly;
    this.disassembly = sanitizedData.disassembly;
    this.throwAway = sanitizedData.throwAway;
    this.itemProps = sanitizedData.itemProps;
    this.media = sanitizedData.media;
  }
  
  static sanitize(data: OrderItemData) {
    const id = convertToOneLine(data.id);
    const title = convertToOneLine(data.title);
    const quantity = Number(data.quantity) || -1;
    const comments = convertToOneLine(data.comments || '', ITEM_COMMENT_MAX_LENGTH);
    const assembly = !!data.assembly;
    const disassembly = !!data.disassembly;
    const throwAway = !!data.throwAway;
    const itemProps = data.itemProps || [];
    const media = data.media;
    return {
      id,
      title,
      quantity,
      comments,
      assembly,
      disassembly,
      throwAway,
      itemProps,
      media,
    };

  }
}