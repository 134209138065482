import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import {FormAddress} from "./client-types";
import {sanitizeMessageTextSpaces} from "./util";

export type OrderContentEntryType = 'address' | 'text' | 'checkbox' | 'select' | 'multiselect' | 'number' | 'media';

export type OrderContentEntryValue = string | boolean | number | string[] | FormAddress;

export abstract class OrderContentEntry<T = any> {
  name: string;
  type: OrderContentEntryType;
  label: string;
  rawValue: T;
  defaultValue: T;
  formLabel: string;
  formErrorMessageRequired?: string;
  formErrorMessageMaxLen?: string;
  formErrorMessageMin?: string;
  formErrorMessageMax?: string;
  options?: Array<{ value: any, formLabel: string, label: string, messageDisplay?: string; }>;
  maxLen?: number;
  min?: number;
  max?: number;

  protected constructor(
    {
      name,
      type,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      maxLen,
      min,
      max,
    }: {
      name: string,
      type: OrderContentEntryType,
      value: T,
      defaultValue: T,
      formLabel: string,
      label: string,
      formErrorMessageRequired: string,
      formErrorMessageMaxLen?: string,
      formErrorMessageMin?: string,
      formErrorMessageMax?: string,
      maxLen?: number,
      min?: number,
      max?: number
    }
  ) {
    this.type = type;
    this.name = name;
    this.rawValue = value;
    this.defaultValue = defaultValue;
    this.formLabel = formLabel;
    this.label = label;
    this.formErrorMessageRequired = formErrorMessageRequired;
    this.formErrorMessageMaxLen = formErrorMessageMaxLen;
    this.formErrorMessageMin = formErrorMessageMin;
    this.formErrorMessageMax = formErrorMessageMax;
    this.maxLen = maxLen || 1000;
    this.min = min;
    this.max = max;
  }
  
  get value() {
    return this.sanitize();
  }

  abstract isValid(): boolean;

  abstract sanitize(): T;

  abstract toSummaryDisplay(): string;
  
  toMessageValueDisplay(...deps: OrderContentEntry<any>[]): string {
    return sanitizeMessageTextSpaces(this.toSummaryDisplay() || '-');
  }
  
  toRequiredMessageDisplay(...deps: OrderContentEntry<any>[]): string {
    const text = this.toMessageValueDisplay(...deps);
    if (text === '-') {
      return `⚠️ יש להשלים שדה זה`;
    } else {
      return text;
    }
  }
  
  isVisible(...deps: OrderContentEntry<any>[]) {  
    return true;
  }
  
  validateRequiredForm() {
    if (!this.isValid()) {
      return this.formErrorMessageRequired;
    }
    return true;
  }
  
  validForSubmission(...deps: OrderContentEntry<any>[]) {
    return this.isValid();
  }
}

export abstract class OrderEntryAddress extends OrderContentEntry<FormAddress> {
  protected constructor({
                          name,
                          value,
                          formLabel,
                          label,
                          formErrorMessageRequired,
                          formErrorMessageMaxLen,
                          formErrorMessageMin,
                          formErrorMessageMax,
                        }: {
    name: string,
    value: FormAddress | null;
    formLabel: string;
    label: string;
    formErrorMessageRequired?: string;
    formErrorMessageMaxLen?: string;
    formErrorMessageMin?: string;
    formErrorMessageMax?: string;
  }) {
    super({
      type: 'address',
      name,
      value,
      defaultValue: null,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
  }

  sanitize() {
    return {
      description: String((this.rawValue?.description || '')).trim().slice(0, 1000),
      mainText: String((this.rawValue?.mainText || '')).trim().slice(0, 1000),
      secondaryText: String((this.rawValue?.secondaryText || '')).trim().slice(0, 1000),
    } satisfies FormAddress;
  }

  toSummaryDisplay() {
    return this.value?.description;
  }

  isValid() {
    const hasDescription = typeof this.value?.description === 'string' && this.value?.description.length > 0;
    const hasMainText = typeof this.value?.mainText === 'string' && this.value?.mainText.length > 0;
    const hasSecondaryText = typeof this.value?.secondaryText === 'string' && this.value?.secondaryText.length > 0;
    return hasDescription && hasMainText && hasSecondaryText;
  }
}

export class OrderEntryText extends OrderContentEntry<string> {
  constructor({
                name,
                value,
                defaultValue,
                formLabel,
                label,
                formErrorMessageRequired,
                formErrorMessageMaxLen,
                formErrorMessageMin,
                formErrorMessageMax,
                maxLen,
              }: {
    name: string,
    value: string,
    defaultValue: string,
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    maxLen?: number,
  }) {
    super({
      type: 'text',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      maxLen,
    });
  }

  sanitize() {
    return String(this.rawValue || '').trim().slice(0, this.maxLen);
  }

  toSummaryDisplay() {
    return this.value;
  }

  isValid() {
    if (typeof this.value !== 'string') {
      return false;
    }
    if (this.formErrorMessageRequired) {
      if (this.value.length === 0) {
        return false;
      }
      if (this.maxLen && this.value.length > this.maxLen) {
        return false;
      }
    }
    return true;
  }
}

export class OrderEntryNumber extends OrderContentEntry<number> {
  constructor({
                name,
                value,
                defaultValue,
                formLabel,
                label,
                formErrorMessageRequired,
                formErrorMessageMaxLen,
                formErrorMessageMin,
                formErrorMessageMax,
                min,
                max,
              }: {
    name: string,
    value: number,
    defaultValue: number,
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    min?: number,
    max?: number,
  }) {
    super({
      type: 'number',
      name,
      value: parseInt(String(value)),
      defaultValue: parseInt(String(defaultValue)),
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
      min,
      max,
    });
  }

  sanitize() {
    const parsed = parseInt(String(this.rawValue));
    if (Number.isNaN(parsed)) {
      return this.defaultValue;
    }
    return parsed;
  }

  toSummaryDisplay() {
    return this.value.toString();
  }

  isValid() {
    if (typeof this.value !== 'number') {
      return false;
    }
    if (typeof this.min === 'number' && this.value < this.min) {
      return false;
    }
    if (typeof this.max === 'number' && this.value > this.max) {
      return false;
    }
    return true;
  }
}

export class OrderEntrySelect extends OrderContentEntry<string> {
  constructor({
                name,
                value,
                defaultValue,
                formLabel,
                label,
                formErrorMessageRequired,
                formErrorMessageMaxLen,
                formErrorMessageMin,
                formErrorMessageMax,
                options,
              }: {
    name: string,
    value: string,
    defaultValue: string,
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    options: Array<{ value: any, formLabel: string, label: string, messageDisplay?: string }>,
  }) {
    super({
      type: 'select',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
    this.options = options;
  }

  sanitize() {
    const selectedOption = this.options.find(option => option.value === this.rawValue);
    return selectedOption?.value || this.defaultValue;
  }

  toSummaryDisplay() {
    const selectedOption = this.options.find(option => option.value === this.value);
    return selectedOption?.formLabel || '-';
  }

  isValid() {
    if (!this.options.map(opt => opt.value).includes(this.value)) {
      return false;
    }
    return true;
  }
}

export class OrderEntryMultiselect extends OrderContentEntry<string[]> {
  constructor({
                name,
                value,
                defaultValue,
                formLabel,
                label,
                formErrorMessageRequired,
                formErrorMessageMaxLen,
                formErrorMessageMin,
                formErrorMessageMax,
                options,
              }: {
    name: string,
    value: string[],
    defaultValue: string[],
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
    options: Array<{ value: any, formLabel: string, label: string, messageDisplay?: string }>,
  }) {
    super({
      type: 'multiselect',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
    this.options = options;
  }

  sanitize() {
    const selectedOptions = this.options.filter(option => this.rawValue.includes(option.value));
    return selectedOptions.map(option => option.value);
  }

  toSummaryDisplay() {
    const selectedOptions = this.options.filter(option => this.value.includes(option.value));
    return selectedOptions.map(option => option.label).join(', ');
  }

  isValid() {
    return this.value.length > 0 && _.difference(this.value, this.options.map(opt => opt.value)).length === 0;
  }
}

export class OrderEntryDay extends OrderContentEntry<string> {
  constructor({
                name,
                value,
                defaultValue,
                formLabel,
                label,
                formErrorMessageRequired,
                formErrorMessageMaxLen,
                formErrorMessageMin,
                formErrorMessageMax,
              }: {
    name: string,
    value: string,
    defaultValue: string | null,
    formLabel: string,
    label: string,
    formErrorMessageRequired?: string,
    formErrorMessageMaxLen?: string,
    formErrorMessageMin?: string,
    formErrorMessageMax?: string,
  }) {
    super({
      type: 'text',
      name,
      value,
      defaultValue,
      formLabel,
      label,
      formErrorMessageRequired,
      formErrorMessageMaxLen,
      formErrorMessageMin,
      formErrorMessageMax,
    });
  }

  sanitize() {
    const d = dayjs(this.rawValue, 'YYYY-MM-DD');
    if (d.isValid()) {
      return d.format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  toSummaryDisplay() {
    const d = dayjs(this.value, 'YYYY-MM-DD');
    if (d.isValid()) {
      return d.format('YYYY-MM-DD');
    } else {
      return null;
    }
  }

  isValid() {
    return dayjs(this.value, 'YYYY-MM-DD').isValid();
  }
}
