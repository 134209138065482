import dayjs from 'dayjs';
import {
  ICPartner,
  ICPartnerPublicProfile,
  PartnerOnboardingStatus,
  PartnerSocials
} from "@tarimli-mono/api/src/shared/client-types";
import {AggregatedRating} from "@tarimli-mono/api/src/shared/rating.ts";
import {ClientPartnerOwnOffer} from "./client-offer.ts";
import {ClientOrderForPartner} from "./client-order.ts";

export class ClientPartnerPublicProfile {
  id: DbId;
  businessName: string;
  phone: string;
  profilePhotos: string[];
  socials: PartnerSocials;
  rating: AggregatedRating;

  constructor(partner: ICPartnerPublicProfile) {
    this.id = partner.id;
    this.businessName = partner.businessName;
    this.phone = partner.phone;
    this.profilePhotos = partner.profilePhotos;
    this.socials = partner.socials;
    this.rating = partner.reviews ? new AggregatedRating(partner.reviews) : null;
  }

  get hasSocials() {
    return !!this.socials?.facebook?.url || !!this.socials?.instagram?.url || !!this.socials?.twitter?.url || !!this.socials?.tiktok?.url || !!this.socials?.website?.url;
  }
}

export class ClientPartner {
  id: DbId;
  phone: string;
  businessName: string;
  profilePhotos: string[];
  socials: PartnerSocials;
  rating: AggregatedRating;
  plusVat: boolean;
  orders: ClientOrderForPartner[];
  onboardingStatus: PartnerOnboardingStatus;
  publicProfile: ClientPartnerPublicProfile;
  createdAt: dayjs.Dayjs;
  updatedAt: dayjs.Dayjs;

  constructor(partner: ICPartner) {
    this.id = partner.id;
    this.phone = partner.phone;
    this.businessName = partner.businessName;
    this.profilePhotos = partner.profilePhotos;
    this.plusVat = partner.plusVat;
    this.socials = partner.socials;
    this.rating = partner.reviews ? new AggregatedRating(partner.reviews) : null;
    this.orders = partner.orders.map(order => new ClientOrderForPartner(order));
    this.onboardingStatus = partner.onboardingStatus;
    this.publicProfile = new ClientPartnerPublicProfile({
      id: partner.id,
      businessName: partner.businessName,
      phone: partner.phone,
      profilePhotos: partner.profilePhotos,
      socials: partner.socials,
      reviews: partner.reviews,
    });
    this.createdAt = dayjs(partner.createdAt);
    this.updatedAt = dayjs(partner.updatedAt);
  }

  get canSubmitOffers() {
    // return this.onboardingStatus?.registrationCompleted && this.onboardingStatus?.termsConfirmed;
    return this.onboardingStatus?.registrationCompleted;
  }

  get onboardingRegistrationCompleted() {
    return !!this.onboardingStatus?.registrationCompleted;
  }

  findOrder(orderId: number) {
    const order = this.orders.find(or => or.id === orderId);
    return order || null;
  }

  // get avgRating() {
  //   const ratings = this.socials.map(social => social.rating).filter(r => typeof r === 'number' && r > 0);
  //   const ratingsSum = ratings.reduce((acc, rating) => acc + rating, 0);
  //   return Math.round(ratingsSum / ratings.length * 10) / 10;
  // }

  get offers() {
    return this.orders.map(order => order.offer).filter(offer => !!offer);
  }
  
  get hasAcceptedOffers() {
    return this.orders.some(order => order.offer?.status === 'accepted');
  }

  get hasOrders() {
    return this.orders.length > 0;
  }

  get hasOpenOrders() {
    return this.openOrders.length > 0;
  }
  
  get hasOrdersForOffer() {
    return this.ordersForOffer.length > 0;
  }

  get openOrders() {
    return this.orders.filter(order => !order.isExpired && (order.offer?.status !== 'accepted'));
  }
  
  get ordersForOffer() {
    return this.orders.filter(order => !order.isExpired &&  (order.offer?.status !== 'declined') && (order.offer?.status !== 'submitted') && (order.offer?.status !== 'accepted'));
  }

  get submittedOffersOrders() {
    return this.orders.filter(order => order.offer?.status === 'submitted' && !order.isExpired);
  }

  get hasSubmittedOffers() {
    return this.submittedOffersOrders.length > 0;
  }
  
  get acceptedOffersOrders() {
    return this.orders.filter(order => order.offer?.status === 'accepted');
  }

  get declinedOffersOrders() {
    return this.orders.filter(order => order.offer?.status === 'declined');
  }

  get expiredOrders() {
    return this.orders.filter(order => order.offer?.status !== 'accepted' && order.isExpired);
  }

  get hasDeclinedOffers() {
    return this.declinedOffersOrders.length > 0;
  }

  get hasExpiredOrders() {
    return this.expiredOrders.length > 0;
  }

}
