import {createFileRoute, Navigate} from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/partner/terms-confirmed')({
  component: PartnerTermsConfirmedPage,
})

export function PartnerTermsConfirmedPage() {
  /* @ts-expect-error */
  return <Navigate to={'/partner/orders'} />
}
