import {FormAddress} from "./client-types";
import {
  OrderContentEntry,
  OrderEntryAddress,
  OrderEntryDay,
  OrderEntryMultiselect,
  OrderEntryNumber,
  OrderEntrySelect,
  OrderEntryText
} from "./order-entries-types";
import {sanitizeMessageTextSpaces} from "./util";

export type OrderContentValues = {
  _version?: string,
  stageIndex?: number,
  phone?: string,
  pdfFile?: string,
  v_test_field1?: string,
  firstName?: string,
  lastName?: string,
  departAddress?: FormAddress,
  departFloor?: string,
  departRooms?: string,
  departParking?: string,
  departParkingComments?: string,
  departLift?: string,
  departLiftComments?: string,
  departCrane?: string,
  departCraneComments?: string,
  departExternalSpace?: string[],
  departBalconyCount?: string,
  departHouseStructure?: string,
  departHouseFloorsNum?: string,
  arriveAddress?: FormAddress,
  arriveFloor?: string,
  arriveParking?: string,
  arriveParkingComments?: string,
  arriveLift?: string,
  arriveCrane?: string,
  arriveCraneComments?: string,
  arriveHouseStructure?: string,
  arriveHouseFloorsNum?: string,
  dateDay?: string,
  dateDayHours?: string[],
  bagsCount?: number,
  suitcasesCount?: number,
  sacksCount?: number,
  boxesCount?: number,
  orderComments?: string,
  additionalOffers?: string[],
}
export type OrderContentNames = keyof OrderContentValues;

const entryWithCommentsValueDisplay = (value: string, allOptions: OrderContentEntry<any>['options'], optionValuesForComments: string[], commentsValueDisplay?: string) => {
  let text = allOptions.find(o => o.value === value)?.label || '-';
  if (optionValuesForComments.includes(value)) {
    text = `${text} (${commentsValueDisplay || '-'})`;
  }
  text = sanitizeMessageTextSpaces(text);
  return text;
}

export class StageIndex extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'stageIndex',
      value,
      defaultValue: 0,
      formLabel: '',
      label: '',
      min: 0,
    });
  }
}

export class OrderPhoneField extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'phone',
      value,
      defaultValue: '',
      formLabel: 'טלפון',
      label: 'טלפון',
      maxLen: 30,
    });
  }
}

export class FirstNameField extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'firstName',
      value,
      defaultValue: '',
      formLabel: 'שם פרטי',
      label: 'שם פרטי',
      formErrorMessageRequired: 'נא להזין שם פרטי',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }
}

export class LastNameField extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'lastName',
      value,
      defaultValue: '',
      formLabel: 'שם משפחה',
      label: 'שם משפחה',
      formErrorMessageRequired: 'נא להזין שם משפחה',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }
}

export class DepartAddress extends OrderEntryAddress {
  constructor(value: FormAddress) {
    super({
      name: 'depart.address',
      value,
      formLabel: 'כתובת מלאה',
      label: 'מאיפה',
      formErrorMessageRequired: 'נא לבחור כתובת מלאה',
    });
  }
}

export class ArriveAddress extends OrderEntryAddress {
  constructor(value: FormAddress) {
    super({
      name: 'arrive.address',
      value,
      formLabel: 'כתובת מלאה',
      label: 'לאן',
      formErrorMessageRequired: 'נא לבחור כתובת מלאה',
    });
  }
}

export class DepartFloor extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'depart.floor',
      value,
      defaultValue: '',
      formLabel: 'קומה',
      label: 'קומה',
      formErrorMessageRequired: 'נא לציין קומה',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }
}

export class DepartRooms extends OrderEntrySelect {
  static options = [
    {value: '1', formLabel: '1', label: '1'},
    {value: '1.5', formLabel: '1.5', label: '1.5'},
    {value: '2', formLabel: '2', label: '2'},
    {value: '2.5', formLabel: '2.5', label: '2.5'},
    {value: '3', formLabel: '3', label: '3'},
    {value: '3.5', formLabel: '3.5', label: '3.5'},
    {value: '4', formLabel: '4', label: '4'},
    {value: '4.5', formLabel: '4.5', label: '4.5'},
    {value: '5', formLabel: '5', label: '5'},
    {value: '5.5', formLabel: '5.5', label: '5.5'},
    {value: '6', formLabel: '6', label: '6'},
    {value: '6.5', formLabel: '6.5', label: '6.5'},
    {value: '7', formLabel: '7', label: '7'},
    {value: '7.5', formLabel: '7.5', label: '7.5'},
    {value: '8', formLabel: '8', label: '8'},
    {value: '8.5', formLabel: '8.5', label: '8.5'},
    {value: '9', formLabel: '9', label: '9'},
    {value: '9.5', formLabel: '9.5', label: '9.5'},
    {value: '10', formLabel: '10', label: '10'},
    {value: '10+', formLabel: 'יותר מ-10', label: 'יותר מ-10'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.rooms',
      value,
      defaultValue: '',
      formLabel: 'כמה חדרים',
      label: 'חדרים',
      formErrorMessageRequired: 'נא לבחור מספר חדרים',
      options: DepartRooms.options,
    });
  }
}

export class DepartParking extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'יש חניה'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'אין חניה'},
    {value: 'maybe', formLabel: 'אולי', label: 'אולי', messageDisplay: 'אולי יש'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.parking',
      value,
      defaultValue: '',
      formLabel: 'האם יש למשאית חניה נוחה וקרובה להעמסה?',
      label: 'חניה',
      formErrorMessageRequired: 'לציין האם יש חניה',
      options: DepartParking.options,
    });
  }
  toMessageValueDisplay(comments: DepartParkingComments) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['no', 'maybe'], comments.toMessageValueDisplay());
  }

  validForSubmission(comments: DepartParkingComments) {
    if (this.value === 'maybe') {
      return comments.isValid() && comments.value.length > 0;
    } else {
      return this.isValid();
    }
  }
}

export class DepartParkingComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'depart.parkingComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על החניה',
      label: 'הערות על החניה',
      formErrorMessageRequired: 'נא להזין הערות על החניה',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }
  
  validForSubmission(parking: DepartParking) {
    if (parking.value === 'maybe') {
      return this.isValid() && this.value.length > 0;
    } else {
      return true;
    }
  }

  isVisible(parking: DepartParking) {
    return ['maybe', 'no'].includes(parking.value);
  }
}

export class DepartLift extends OrderEntrySelect {
  static options = [
    {value: 'regular-lift', formLabel: 'מעלית רגילה', label: 'מעלית רגילה'},
    {value: 'no-lift', formLabel: 'אין מעלית', label: 'אין מעלית'},
    {value: 'small-lift', formLabel: 'מעלית קטנה', label: 'מעלית קטנה'},
    {value: 'large-lift', formLabel: 'מעלית משא', label: 'מעלית משא'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.lift',
      value,
      defaultValue: '',
      formLabel: 'האם יש מעלית בבניין?',
      label: 'מעלית',
      formErrorMessageRequired: 'נא לבחור מעלית',
      options: DepartLift.options,
    });
  }
}

export class DepartCrane extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן', messageDisplay: 'צריך מנוף'},
    {value: 'no', formLabel: 'לא', label: 'לא', messageDisplay: 'לא צריך מנוף'},
    {value: 'maybe', formLabel: 'אולי', label: 'אולי', messageDisplay: 'אולי'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.crane',
      value,
      defaultValue: '',
      formLabel: 'ידרש מנוף?',
      label: 'מנוף',
      formErrorMessageRequired: 'נא לציין אם יידרש מנוף',
      options: DepartCrane.options,
    });
  }

  toMessageValueDisplay(comments: DepartCraneComments) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['yes', 'maybe'], comments.toMessageValueDisplay());
  }

  validForSubmission(comments: DepartCraneComments) {
    if (this.value === 'maybe') {
      return comments.isValid() && comments.value.length > 0;
    } else {
      return this.isValid();
    }
  }
}

export class DepartCraneComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'depart.craneComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על המנוף',
      label: 'הערות על המנוף',
      formErrorMessageRequired: 'נא להזין הערות על המנוף',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(crane: DepartCrane) {
    if (crane.value === 'maybe') {
      return this.isValid() && this.value.length > 0;
    } else {
      return true;
    }
  }

  isVisible(crane: DepartCrane) {
    return ['maybe', 'yes'].includes(crane.value);
  }
}

export class DepartExternalSpace extends OrderEntryMultiselect {
  static options = [
    {value: 'balcony', formLabel: 'מרפסת', label: 'מרפסת'},
    {value: 'garden', formLabel: 'גינה', label: 'גינה'},
    {value: 'roof', formLabel: 'גג', label: 'גג'},
    {value: 'none', formLabel: 'אין', label: 'אין'},
  ];
  constructor(value: string[]) {
    super({
      name: 'depart.externalSpace',
      value,
      defaultValue: [],
      formLabel: 'שטחים חיצוניים',
      label: 'שטחים חיצוניים',
      formErrorMessageRequired: 'נא לציין אם יש שטחים חיצוניים לבית',
      options: DepartExternalSpace.options,
    });
  }

  validForSubmission(balconyCount: DepartBalconyCount) {
    if (this.value.includes('balcony')) {
      return balconyCount.isValid();
    } else {
      return this.isValid();
    }
  }
}

export class DepartBalconyCount extends OrderEntrySelect {
  static options = [
    {value: '1', formLabel: '1', label: '1', messageDisplay: 'מרפסת אחת'},
    {value: '2', formLabel: '2', label: '2', messageDisplay: 'שתי מרפסות'},
    {value: '3', formLabel: '3', label: '3', messageDisplay: '3 מרפסות'},
    {value: '4', formLabel: '4', label: '4', messageDisplay: '4 מרפסות'},
    {value: '5', formLabel: '5', label: '5', messageDisplay: '5 מרפסות'},
    {value: '6', formLabel: '6', label: '6', messageDisplay: '6 מרפסות'},
    {value: '7', formLabel: '7', label: '7', messageDisplay: '7 מרפסות'},
    {value: '8', formLabel: '8', label: '8', messageDisplay: '8 מרפסות'},
    {value: '9', formLabel: '9', label: '9', messageDisplay: '9 מרפסות'},
    {value: '10', formLabel: '10', label: '10', messageDisplay: '10 מרפסות'},
    {value: '10+', formLabel: 'יותר מ-10', label: 'יותר מ-10', messageDisplay: 'יותר מ-10 מרפסות'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.balconyCount',
      value,
      defaultValue: '',
      formLabel: 'כמה מרפסות יש בבית?',
      label: 'מרפסות',
      formErrorMessageRequired: 'נא לציין מספר מרפסות',
      options: DepartBalconyCount.options,
    });
  }

  validForSubmission(externalSpace: DepartExternalSpace) {
    if (externalSpace.value.includes('balcony')) {
      return this.isValid();
    } else {
      return true;
    }
  }

  isVisible(externalSpace: DepartExternalSpace) {
    return externalSpace.value.includes('balcony');
  }
}

export class DepartHouseStructure extends OrderEntrySelect {
  static options = [
    {value: 'gallery', formLabel: 'גלריה', label: 'גלריה'},
    {value: 'one-floor', formLabel: 'קומה אחת', label: 'קומה אחת'},
    {value: 'more-floors', formLabel: 'יותר מקומה אחת', label: 'יותר מקומה אחת'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.houseStructure',
      value,
      defaultValue: '',
      formLabel: 'האם יש לכם בבית / בדירה:',
      label: 'יש בדירה',
      formErrorMessageRequired: 'נא לבחור אפשרות של קומות',
      options: DepartHouseStructure.options,
    });
  }

  toMessageValueDisplay(externalSpaces: DepartExternalSpace, balconyCount: DepartBalconyCount, floorsNum: DepartHouseFloorsNum) {
    const sentences = [];
    if (this.value === 'gallery') {
      sentences.push(this.options.find(o => o.value === this.value)?.label);
    }
    if (this.value === 'one-floor') {
      sentences.push(this.options.find(o => o.value === this.value)?.label);
    }
    if (this.value === 'more-floors') {
      sentences.push(`${floorsNum.options.find(o => o.value === floorsNum.value)?.messageDisplay}`);
    }
    if (externalSpaces.value.includes('balcony')) {
      sentences.push(balconyCount.options.find(o => o.value === balconyCount.value)?.messageDisplay);
    }
    if (externalSpaces.value.includes('garden')) {
      sentences.push('גינה');
    }
    if (externalSpaces.value.includes('roof')) {
      sentences.push('גג');
    }
    let text = sentences.length > 0 ? sentences.join(', ') : '-';
    text = sanitizeMessageTextSpaces(text);
    return text;
  }
  
  validForSubmission(houseStructure: DepartHouseFloorsNum): boolean {
    if (this.value === 'more-floors') {
      return houseStructure.isValid();
    } else {
      return this.isValid();
    }
  }
}

export class DepartHouseFloorsNum extends OrderEntrySelect {
  static options = [
    {value: '2', formLabel: '2', label: '2', messageDisplay: 'שתי קומות'},
    {value: '3', formLabel: '3', label: '3', messageDisplay: '3 קומות'},
    {value: '4', formLabel: '4', label: '4', messageDisplay: '4 קומות'},
    {value: '5', formLabel: '5', label: '5', messageDisplay: '5 קומות'},
    {value: '5+', formLabel: 'יותר מ-5', label: 'יותר מ-5', messageDisplay: 'יותר מ-5 קומות'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.houseFloorsNum',
      value,
      defaultValue: '',
      formLabel: 'כמה קומות יש בבית?',
      label: 'קומות',
      formErrorMessageRequired: 'נא לציין מספר קומות',
      options: DepartHouseFloorsNum.options,
    });
  }

  validForSubmission(houseStructure: DepartHouseStructure) {
    if (houseStructure.value.includes('more-floors')) {
      return this.isValid();
    } else {
      return true;
    }
  }

  isVisible(houseStructure: DepartHouseStructure) {
    return houseStructure.value.includes('more-floors');
  }
}

export class ArriveFloor extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'arrive.floor',
      value,
      defaultValue: '',
      formLabel: 'קומה',
      label: 'קומה',
      formErrorMessageRequired: 'נא לציין קומה',
      formErrorMessageMaxLen: 'לא יותר מ-30 תווים בבקשה',
      maxLen: 30,
    });
  }
}

export class ArriveParking extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'לא', label: 'לא'},
    {value: 'maybe', formLabel: 'אולי', label: 'אולי'},
  ];
  constructor(value: string) {
    super({
      name: 'arrive.parking',
      value,
      defaultValue: '',
      formLabel: 'האם יש למשאית חניה נוחה וקרובה להעמסה?',
      label: 'חניה',
      formErrorMessageRequired: 'לציין האם יש חניה',
      options: ArriveParking.options,
    });
  }
  
  toMessageValueDisplay(comments: ArriveParkingComments) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['no', 'maybe'], comments.toMessageValueDisplay());
  }
  
  validForSubmission(comments: ArriveParkingComments) {
    if (this.value === 'maybe') {
      return comments.isValid() && comments.value.length > 0;
    } else {
      return this.isValid();
    }
  }
}

export class ArriveParkingComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'arrive.parkingComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על החניה',
      label: 'הערות על החניה',
      formErrorMessageRequired: 'נא להזין הערות על החניה',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(parking: ArriveParking) {
    if (parking.value === 'maybe') {
      return this.isValid() && this.value.length > 0;
    } else {
      return true;
    }
  }

  isVisible(parking: ArriveParking) {
    return ['maybe', 'no'].includes(parking.value);
  }
}

export class ArriveLift extends OrderEntrySelect {
  static options = [
    {value: 'regular-lift', formLabel: 'מעלית רגילה', label: 'מעלית רגילה'},
    {value: 'no-lift', formLabel: 'אין מעלית', label: 'אין מעלית'},
    {value: 'small-lift', formLabel: 'מעלית קטנה', label: 'מעלית קטנה'},
    {value: 'large-lift', formLabel: 'מעלית משא', label: 'מעלית משא'},
  ];
  constructor(value: string) {
    super({
      name: 'arrive.lift',
      value,
      defaultValue: '',
      formLabel: 'האם יש מעלית בבניין?',
      label: 'מעלית',
      formErrorMessageRequired: 'נא לבחור מעלית',
      options: ArriveLift.options,
    });
  }
}

export class ArriveCrane extends OrderEntrySelect {
  static options = [
    {value: 'yes', formLabel: 'כן', label: 'כן'},
    {value: 'no', formLabel: 'לא', label: 'לא'},
    {value: 'maybe', formLabel: 'אולי', label: 'אולי'},
  ];
  constructor(value: string) {
    super({
      name: 'arrive.crane',
      value,
      defaultValue: '',
      formLabel: 'ידרש מנוף?',
      label: 'מנוף',
      formErrorMessageRequired: 'נא לציין אם יידרש מנוף',
      options: ArriveCrane.options,
    });
  }
  
  toMessageValueDisplay(comments: ArriveCraneComments) {
    return entryWithCommentsValueDisplay(this.value, this.options, ['yes', 'maybe'], comments.toMessageValueDisplay());
  }
  
  validForSubmission(comments: ArriveCraneComments) {
    if (this.value === 'maybe') {
      return comments.isValid() && comments.value.length > 0;
    } else {
      return this.isValid();
    }
  }
}

export class ArriveCraneComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'arrive.craneComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות על המנוף',
      label: 'הערות על המנוף',
      formErrorMessageRequired: 'נא להזין הערות על המנוף',
      formErrorMessageMaxLen: 'לא יותר מ-100 תווים בבקשה',
      maxLen: 100,
    });
  }

  validForSubmission(crane: ArriveCrane) {
    if (crane.value === 'maybe') {
      return this.isValid() && this.value.length > 0;
    } else {
      return true;
    }
  }

  isVisible(crane: ArriveCrane) {
    return ['maybe', 'yes'].includes(crane.value);
  }
}

export class ArriveHouseStructure extends OrderEntrySelect {
  static options = [
    {value: 'gallery', formLabel: 'גלריה', label: 'גלריה'},
    {value: 'one-floor', formLabel: 'קומה אחת', label: 'קומה אחת'},
    {value: 'more-floors', formLabel: 'יותר מקומה אחת', label: 'יותר מקומה אחת'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.houseStructure',
      value,
      defaultValue: '',
      formLabel: 'האם יש לכם בבית / בדירה:',
      label: 'יש בדירה',
      formErrorMessageRequired: 'נא לבחור אפשרות של קומות בדירה',
      options: ArriveHouseStructure.options,
    });
  }
  
  toMessageValueDisplay(floorsNum: ArriveHouseFloorsNum) {
    let text = '-';
    if (this.value === 'gallery') {
      text = this.options.find(o => o.value === this.value)?.label || '-';
    }
    if (this.value === 'one-floor') {
      text = this.options.find(o => o.value === this.value)?.label || '-';
    }
    if (this.value === 'more-floors') {
      text = floorsNum.options.find(o => o.value === floorsNum.value)?.messageDisplay || '-';
    }
    text = sanitizeMessageTextSpaces(text);
    return text;
  }
  
  validForSubmission(arriveFloorsNum: ArriveHouseFloorsNum) {
    if (this.value === 'more-floors') {
      return arriveFloorsNum.isValid();
    } else {
      return this.isValid();
    }
  }
}

export class ArriveHouseFloorsNum extends OrderEntrySelect {
  static options = [
    {value: '2', formLabel: '2', label: '2', messageDisplay: 'שתי קומות'},
    {value: '3', formLabel: '3', label: '3', messageDisplay: '3 קומות'},
    {value: '4', formLabel: '4', label: '4', messageDisplay: '4 קומות'},
    {value: '5', formLabel: '5', label: '5', messageDisplay: '5 קומות'},
    {value: '5+', formLabel: 'יותר מ-5', label: 'יותר מ-5', messageDisplay: 'יותר מ-5 קומות'},
  ];
  constructor(value: string) {
    super({
      name: 'depart.houseFloorsNum',
      value,
      defaultValue: '',
      formLabel: 'כמה קומות יש בבית?',
      label: 'קומות',
      formErrorMessageRequired: 'נא לציין מספר קומות',
      options: ArriveHouseFloorsNum.options,
    });
  }

  validForSubmission(houseStructure: ArriveHouseStructure) {
    if (houseStructure.value.includes('more-floors')) {
      return this.isValid();
    } else {
      return true;
    }
  }

  isVisible(houseStructure: ArriveHouseStructure) {
    return houseStructure.value.includes('more-floors');
  }
}

export class OrderDateDay extends OrderEntryDay {
  constructor(value: string) {
    super({
      name: 'dateDay',
      value,
      defaultValue: null,
      formLabel: 'תאריך הובלה',
      label: 'מתי',
      formErrorMessageRequired: 'נא לבחור תאריך הובלה',
    });
  }
}

export class OrderDateDayHours extends OrderEntryMultiselect {
  constructor(value: string[]) {
    super({
      name: 'dateDayHours',
      value,
      defaultValue: [],
      formLabel: 'באיזה שעות?',
      label: 'שעות',
      formErrorMessageRequired: 'נא לבחור שעות מעבר',
      options: [
        {value: 'morning', formLabel: 'בוקר', label: 'בוקר'},
        {value: 'noon', formLabel: 'צהריים', label: 'צהריים'},
        {value: 'evening', formLabel: 'ערב', label: 'ערב'},
      ],
    });
  }
}

export class BagsCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'bagsCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה תיקים?',
      label: 'תיקים',
      min: 0,
    });
  }
  toMessageValueDisplay(suitcases: SuitcasesCount, sacks: SacksCount, boxes: BoxesCount) {
    const sentences = [];
    if (this.value > 0) {
      sentences.push(`${this.value} ${this.label}`);
    }
    if (suitcases.value > 0) {
      sentences.push(`${suitcases.value} ${suitcases.label}`);
    }
    if (sacks.value > 0) {
      sentences.push(`${sacks.value} ${sacks.label}`);
    }
    if (boxes.value > 0) {
      sentences.push(`${boxes.value} ${boxes.label}`);
    }
    let text = sentences.length > 0 ? sentences.join(', ') : '-';
    text = sanitizeMessageTextSpaces(text);
    return text;
  }
}

export class SuitcasesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'suitcasesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה מזוודות?',
      label: 'מזוודות',
      min: 0,
    });
  }
}

export class SacksCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'sacksCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה שקיות?',
      label: 'שקיות',
      min: 0,
    });
  }
}

export class BoxesCount extends OrderEntryNumber {
  constructor(value: number) {
    super({
      name: 'boxesCount',
      value,
      defaultValue: 0,
      formLabel: 'כמה ארגזים?',
      label: 'ארגזים',
      min: 0,
    });
  }
}

export class OrderComments extends OrderEntryText {
  constructor(value: string) {
    super({
      name: 'orderComments',
      value,
      defaultValue: '',
      formLabel: 'הערות נוספות להובלה',
      label: 'הערות להובלה',
      formErrorMessageMaxLen: 'לא יותר מ-200 תווים בבקשה',
      maxLen: 200,
    });
  }
  toMessageValueDisplay() {
    return sanitizeMessageTextSpaces(this.value) || '-';
  }
}

export class OrderAdditionalOffers extends OrderEntryMultiselect {
  static options = [
    {value: 'ציוד אריזה', formLabel: 'ציוד אריזה', label: 'ציוד אריזה'},
    {value: 'אריזת הבית', formLabel: 'אריזת הבית', label: 'אריזת הבית'},
    {value: 'פריקת ארגזים וסידור הבית', formLabel: 'פריקת ארגזים וסידור הבית', label: 'פריקת ארגזים וסידור הבית'},
    {value: 'סיוד', formLabel: 'סיוד', label: 'סיוד'},
    {value: 'ניקיון', formLabel: 'ניקיון', label: 'ניקיון'},
  ];
  constructor(value: string[]) {
    super({
      name: 'additionalOffers',
      value,
      defaultValue: [],
      formLabel: 'לצרכים סטטיסטיים - האם בנוסף להובלה תרצו לקבל הצעת מחיר לאחד או יותר מהשירותים הבאים?',
      label: 'הצעות',
      options: OrderAdditionalOffers.options,
    });
  }
}