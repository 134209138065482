import Link from "@mui/material/Link";
import {Button} from "@mui/material";

export function useIsAdminDashboard() {
  return (window as any).isAdminDashboard;
}

export const AdminEditOrderLink = ({section}: { section: string }) => {
  const isAdminDashboard = useIsAdminDashboard();
  if (!isAdminDashboard) {
    return null;
  }
  const onClickEdit = () => {
    document.dispatchEvent(new CustomEvent('edit-order', {detail: {section}}));
  }
  return <Button onClick={onClickEdit}>Edit {section}</Button>;
}