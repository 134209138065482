import Typography from "@mui/material/Typography";
import pdfIcon from "/pdf-icon.png";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

const labels = {
  orderNumber: (num: number) => `הזמנה #${num}`,
}

export function OrderNumber({orderId, sx, pdfUrl}: { orderId: number, sx?: any, pdfUrl?: string }) {
  const content = <Stack direction={'row'} gap={1} sx={{
    border: '1px solid #E0E0E0',
    padding: '5px 8px',
    borderRadius: '5px',
    backgroundColor: '#F5F5F5',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    ...(sx || {}),
  }}>

    {pdfUrl && <img src={pdfIcon} style={{
      width: 25,
    }}/>}
    <Typography variant="subtitle2">{labels.orderNumber(orderId)}</Typography>

  </Stack>;
  return pdfUrl ?
    <Link href={pdfUrl}>
      {content}
    </Link> : content;
}