import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useRouter} from "@tanstack/react-router";
import Divider from "@mui/material/Divider";
import {SxProps} from "@mui/material/styles";
import {config} from "../config.ts";
import {IconWhatsapp} from "./icons/IconWhatsapp.tsx";
import {ClientCustomerOwnOrder} from "../model/client-order.ts";
import React from "react";
import {useMutationCancelEditOrder} from "../services/api-order.ts";

const labels = {
  stage: (stage: number, totalStages: number) => `שלב ${stage} מתוך ${totalStages}`,
  next: 'ממשיכים',
  fillRequired: 'יש למלא את את השדות חובה לפני שממשיכים',
  change: 'עריכה',
  editingOrder: 'עדכון הובלה',
  editingOrderWarning: 'שימו ❤️ הצעות מחיר שהתקבלו יבוטלו, ואנו נבקש ממובילים להגיש הצעות מחיר מחדש',
  fromAddress: 'הבית שעוזבים',
  toAddress: 'הבית החדש',
  movingDate: 'מתי עוברים',
  orderSummary: 'סיכום ההובלה',
  cancelEdit: 'ביטול עדכון',
  choseItems: (count: string) => `בחרת ${count} פריטים`,
  packages: (count: string, orderComments: string) => <Stack gap={1}>
    <Typography>{count === '0' ? 'אין תכולה ארוזה' : `תכולה ארוזה: ${count} פריטים`}</Typography>
    <Typography>{`הערות להזמנה: ${orderComments || 'אין'}`}</Typography>
  </Stack>,
  customerService: (fontSize: number = 11, fontWeight: number = 600) => <Stack><Typography
    sx={{fontSize, fontWeight, lineHeight: 1, whiteSpace: 'nowrap'}}>דברו איתנו</Typography></Stack>,
  soFar: 'עד כאן אמרנו ככה:',
  submitError: 'סליחה קרתה תקלה 😔 נא לנסות שוב. אם הבעיה נמשכת נא ליצור קשר עם שירות הלקוחות שלנו.',
  error: 'סליחה תקלה :(',
}

export const CustomerServiceButton = ({title, variant, sx}: {
  title?: string,
  variant?: 'large' | 'small',
  sx?: SxProps
}) => {
  const isLarge = variant === 'large';
  const isSmall = variant === 'small';
  const onCustomerServiceClick = async () => {
    window.open(config().customerServiceLink, '_blank');
  };
  return (<Stack onClick={onCustomerServiceClick} direction={'row'} gap={1} sx={{
    cursor: 'pointer',
    alignItems: "center",
    backgroundColor: !isSmall ? "#25D366" : '#ffffff',
    outline: isSmall ? '1px solid #25D366' : 'none',
    // padding: isLarge ? 2 : 0.9,
    px: isLarge ? 3 : isSmall ? 0.8 : 1.5,
    py: isLarge ? 2.5 : isSmall ? 0.5 : 1.2,
    borderRadius: 1,
    color: !isSmall ? '#fff' : '#25D366',
    ...(sx || {}),
  }}>
    <Box>
      {labels.customerService(isLarge ? 25 : isSmall ? 11 : 14, isSmall ? 800 : 600)}
    </Box>
    <IconWhatsapp
      sx={{color: !isSmall ? '#fff' : '#25D366', fontSize: isLarge ? 30 : isSmall ? 14 : 20, marginBottom: '0'}}/>
  </Stack>);
}

export const OrderId = ({id}: { id: number }) => {
  return (
    <Typography sx={{
      // fontSize: 12,
      opacity: 0.5,
    }}>{`#${id}`}</Typography>
  );
}

export const EditHeader = ({order}: { order: ClientCustomerOwnOrder }) => {
  const router = useRouter();
  const cancelEditMutation = useMutationCancelEditOrder();
  const onClickCancelEdit = async () => {
    await cancelEditMutation.mutateAsync({orderId: order.id});
    setTimeout(async () => {
      // @ts-expect-error
      await router.navigate({
        to: `/orders`,
      });
    }, 50);
  }
  return (
    <Stack
      direction={'row'}
      gap={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        backgroundColor: '#4b00ba',
        color: '#ffffff',
        borderRadius: 1,
        p: 2,
      }}
    >
      <Stack>
        <Typography sx={{
          fontWeight: 600,
          fontSize: 18,
        }}>{labels.editingOrder}</Typography>
        <Typography sx={{
          fontSize: 14,
        }}>{labels.editingOrderWarning}</Typography>
      </Stack>
      <LoadingButton
        sx={{
          borderColor: '#ffffff',
          color: '#ffffff',
        }}
        onClick={onClickCancelEdit}
        endIcon={<CloseIcon/>}
        loading={!order.isEdit} variant={'outlined'}>
        {labels.cancelEdit}
      </LoadingButton>
      {
        cancelEditMutation.isError && !cancelEditMutation.isPending ? <Typography sx={{
          fontSize: 12,
          opacity: 0.5,
        }}>{labels.error}</Typography> : null
      }
    </Stack>
  )
}

export const EditOrderHeader = ({stage, totalStages, order, hideSummary}: {
  order: ClientCustomerOwnOrder,
  stage: number,
  totalStages: number,
  hideSummary?: boolean,
}) => {
  const router = useRouter();

  const onClickChangeFromAddress = async () => {
    await router.navigate({
      to: `/order/${order.id}`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const onClickChangeToAddress = async () => {
    await router.navigate({
      to: `/order/${order.id}/arrive`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
  const onClickChangeDate = async () => {
    await router.navigate({
      to: `/order/${order.id}/date`,
      resetScroll: true,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickChangeItems = async () => {
    await router.navigate({
      to: `/order/${order.id}/items`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickOrderSummary = async () => {
    await router.navigate({
      to: `/order/${order.id}/summary`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const onClickChangePackages = async () => {
    await router.navigate({
      to: `/order/${order.id}/boxes`,
    });
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const progressItem = (title: string, value: any, subtitle: string, onButtonClick: () => void) => (
    <Stack direction={"row"} gap={2} sx={{
      alignItems: "center",
    }}>
      <Box sx={{
        flexGrow: 1
      }}>
        <Stack gap={0}>
          {title ? <Typography sx={{
            opacity: 0.5,
            fontSize: 11,
            fontWeight: 500
          }}>{title}</Typography> : null}
          <Box sx={{
            fontSize: 16,
            opacity: 0.5,
          }}>{value}</Box>
          {subtitle ? <Typography sx={{
            fontSize: 12,
            opacity: 0.5,
          }}>{subtitle}</Typography> : null}
        </Stack>
      </Box>
      <Box>
        <Button variant={"outlined"} size={"small"} onClick={onButtonClick}
                sx={{whiteSpace: 'nowrap'}}>{labels.change}</Button>
      </Box>
    </Stack>);
  return (
    <>
      {order.isEdit ?
        <EditHeader order={order}/>
        :
        null
      }
      {order.content.entries.stageIndex.value > 0 && !hideSummary ?
        <Stack sx={{
          backgroundColor: "white",
          borderRadius: 1,
          clipPath: 'padding-box',
        }}>
          <Stack sx={{
            padding: 2,
          }} gap={2}>
            <Stack gap={2}>
              {order.isEdit ? null : <Stack gap={1}><Typography sx={{
                fontWeight: 600,
                fontSize: 18,
              }}>{labels.soFar}</Typography>
                <Divider/>
              </Stack>}

              {order.content.entries.stageIndex.value > 5 ? progressItem(labels.fromAddress, order.content.strDepartAddress, null, onClickChangeFromAddress) : null}
              {order.content.entries.stageIndex.value > 15 ? progressItem(labels.toAddress, order.content.strArriveAddress, null, onClickChangeToAddress) : null}
              {order.content.entries.stageIndex.value > 25 ? progressItem(labels.movingDate, order.content.entries.dateDay.toSummaryDisplay(), order.content.movingDateRelativeLabel, onClickChangeDate) : null}
              {order.content.hasItems ? progressItem(null, labels.choseItems(order.content.items.length.toString()), null, onClickChangeItems) : null}
              {order.content.entries.stageIndex.value > 35 ? progressItem(null, labels.packages(order.content.totalPackagesCount.toString(), order.content.entries.orderComments.value), null, onClickChangePackages) : null}
              {order.content.entries.stageIndex.value > 45 ? <Button variant={'outlined'} size={'small'}
                                                                                  onClick={onClickOrderSummary}>{labels.orderSummary}</Button> : null}
            </Stack>
          </Stack>
        </Stack>
        : null}

      <Stack direction={"row"} gap={2} sx={{
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        borderRadius: 1,
        px: 2,
        py: 1,
      }}>
        <Typography sx={{
          fontSize: 22,
        }}>{labels.stage(stage, totalStages)}</Typography>
        <OrderId id={order.id}/>
        {/*<CustomerServiceButton/>*/}
      </Stack>
    </>
  );
};

export const EditStageHeader = ({stage, totalStages, title}: {
  stage: number,
  totalStages: number,
  title: string
}) => {
  return (
    <Box>
      <Stack direction={"row"} sx={{
        backgroundColor: "#F3EBFF",
        padding: 2,
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Typography sx={{fontWeight: 600, fontSize: 20}}>{title}</Typography>
        {/*<Box>*/}
        {/*  <Typography fontSize={14}>{labels['stage'](stage, totalStages)}</Typography>*/}
        {/*</Box>*/}
      </Stack>
    </Box>
  );
};

export const EditStageFooter = ({onPressDone, disabled, loading, hasErrors, nextLabel, submitError}: {
  onPressDone: () => void,
  disabled: boolean,
  loading: boolean,
  hasErrors?: boolean,
  submitError?: boolean,
  nextLabel?: string,
}) => {
  return (
    <Stack direction={"row"} gap={2} sx={{
      padding: 2,
    }}>
      <Stack gap={2} sx={{flexGrow: 1}}>
        <LoadingButton onClick={() => onPressDone()} loading={loading}
                       variant={'contained'}
                       disabled={disabled}>{nextLabel || labels['next']}</LoadingButton>
        {submitError ?
          <Box sx={{textAlign: 'center', marginBottom: 2}}>
            <Typography sx={{
              color: 'red',
              fontSize: 14,
              visibility: submitError ? 'visible' : 'hidden',
            }}>{labels.submitError}</Typography>
          </Box>
          : null
        }
        {hasErrors ?
          <Box sx={{textAlign: 'center', marginBottom: 2}}>
            <Typography sx={{
              color: 'red',
              fontSize: 14,
            }}>{labels['fill-required']}</Typography>
          </Box>
          : null}
      </Stack>
      {/*<Box sx={{alignSelf: 'flex-start'}}>*/}
      {/*  <CustomerServiceButton/>*/}
      {/*</Box>*/}
    </Stack>
  );
}
