import dayjs from 'dayjs'
import {OrderReview} from "./client-types";

export class OrderRating {
    constructor(public review: OrderReview) {
      
    }
    get avgRating() {
      if (!this.review) {
        return null;
      }
      const count = Object.keys(this.review).filter(key => typeof this.review[key] === 'number').length;
      const sum = (this.review.quality || 0) + (this.review.timing || 0) + (this.review.service || 0) + (this.review.experience || 0);
      const avg = Math.round(sum / count * 10) / 10;
      return avg;
    }
    get quality(): number | null {
      if (!this.review || typeof this.review.quality !== 'number') {
        return null;
      }
      return this.review.quality;
    }
    get timing(): number | null {
      if (!this.review || typeof this.review.timing !== 'number') {
        return null;
      }
      return this.review.timing;
    }
    get service(): number | null {
      if (!this.review || typeof this.review.service !== 'number') {
        return null;
      }
      return this.review.service;
    }
    get experience(): number | null {
      if (!this.review || typeof this.review.experience !== 'number') {
        return null;
      }
      return this.review.experience;
    }
    get recommend(): number | null {
      if (!this.review || typeof this.review.recommend !== 'number') {
        return null;
      }
      return this.review.recommend;
    }
    get createdAt() {
      return dayjs(this.review?.createdAt) || null;
    }
    get getCreatedAtFormatted() {
      return dayjs().to(this.createdAt) || null
    }
}

export class AggregatedRating {
    ratings: OrderRating[] = [];
    constructor(reviews: OrderReview[]) {
      this.ratings = reviews.map(r => new OrderRating(r));
    }
    
    get hasRatings() {
      return this.ratings.length > 0;
    }
    
    get count() {
      return this.ratings.length;
    }
    
    get avgRating() {
      const count = this.ratings.filter(orderRating => orderRating.avgRating > 0).length;
      return this.ratings.reduce((acc, orderRating) => acc + orderRating.avgRating, 0) / count;
    }
    
    get avgQuality() {
      const count = this.ratings.filter(orderRating => orderRating.quality > 0).length;
      const sum = this.ratings.reduce((acc, orderRating) => acc + orderRating.quality, 0);
      return sum / count;
    }
    get avgTiming() {
      const count = this.ratings.filter(orderRating => orderRating.timing > 0).length;
      const sum = this.ratings.reduce((acc, orderRating) => acc + orderRating.timing, 0);
      return sum / count;
    }
    get avgService() {
      const count = this.ratings.filter(orderRating => orderRating.service > 0).length;
      const sum = this.ratings.reduce((acc, orderRating) => acc + orderRating.service, 0);
      return sum / count;
    }
    get avgExperience() {
      const count = this.ratings.filter(orderRating => orderRating.experience > 0).length;
      const sum = this.ratings.reduce((acc, orderRating) => acc + orderRating.experience, 0);
      return sum / count;
    }
    get avgRecommend() {
      const count = this.ratings.filter(orderRating => orderRating.recommend > 0).length;
      const sum = this.ratings.reduce((acc, orderRating) => acc + orderRating.recommend, 0);
      return sum / count;
    }
}